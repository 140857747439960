import React from "react";
import Landing from "./components/LandingFace";
import Experty from "./components/Experty";
import Blockers from "./components/Blockers";
import Features from "./components/Features";
import Whyus from "./components/WhyUs";
import Whatweare from "./components/Whatweare";
import PicSpacer from "../../Home/Components/PicSpacer";
import Ready from "./components/Ready";
import Portfolio from "./components/portfoliio"

function index() {
  return (
    <div>
      <Landing />
      <Whatweare />
      <Whyus />
      <Features />
      <Portfolio />
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default index;
