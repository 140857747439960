import React from "react";

import Slide from "../../../../Assets/Images/SEO/SeoFnevol.jpg";
import { Check, FactCheck } from "@mui/icons-material";

function Whatwedo() {
  return (
    <div className="my-28 lg:my-28  md:my-28  sm:my-20  xs:my-20  px-16 md:px-16 sm:px-4 xs:px-4 flex gap-4 flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col   ">
      <div className="flex-1 pr-8 md:pr-0 sm:pr-0 xs:pr-0 ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black mb-4">
          Why Choose Us
        </h3>
        <hr className="w-full weight-900 secondary " />

        <div className="gap-4 w-full lg:w-full md:w-[80%]   sm:py-8 xs:py-8 flex justify-center  items-start flex-1 flex-col    ">
          <div className="p-4 bg-[#333] Transition  rounded flex flex-col justify-left items-left ">
            <div className="flex flex-row justify-left items-center ">
              <Check className="important text-white rounded-full White-Border  whiteshadow text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Collaborative <span className="text-white ">Team</span>
              </h3>
            </div>
            <p className="Whitext">
              When you choose us, you gain a dedicated team of SEO professionals
              who are passionate about helping your business succeed. We work
              closely with you, taking the time to understand your brand, goals,
              and challenges. We believe in fostering a collaborative
              partnership, where your input and feedback are valued every step
              of the way.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Customized 
                <span span className="text-white ">
                   Approach
                </span>
              </h3>
            </div>
            <p className="Whitext">
              We believe in providing personalized solutions that align with
              your business goals. We take the time to understand your target
              audience, industry landscape, and competitors to develop a
              tailored SEO strategy that yields maximum results. Our approach is
              flexible and adaptable, ensuring that we stay ahead of algorithm
              updates and industry trends.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Transparent 
                <span span className="text-white ">
                   Practices
                </span>{" "}
              </h3>
            </div>
            <p className="Whitext">
              We strictly adhere to white-hat SEO practices that comply with
              search engine guidelines. Our ethical approach ensures the
              long-term sustainability of your online presence. We keep you
              informed every step of the way, providing transparent reporting
              and regular communication, so you have complete visibility into
              the progress of your SEO campaigns.y
            </p>
          </div>
        </div>
      </div>
      <div className="flex-1 w-[100%] md:w-[50%]   relative">
        <img
          src={Slide}
          className="w-full md:w-[100%] cover  h-[100%] md:h-full sm:h-[400px] xs:h-[400px]  "
          alt=""
        />
      </div>
    </div>
  );
}

export default Whatwedo;
