import React from "react";

import { Check, FactCheck } from "@mui/icons-material";

function Whatwedo() {
  return (
    <div className="my-28 lg:my-28  md:my-28  sm:my-20  xs:my-20  px-16 md:px-16 sm:px-4 xs:px-4 flex gap-4 flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col   ">
      <div className="flex-1 pr-8 md:pr-0 sm:pr-0 xs:pr-0 ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black mb-4">
          Why Choose Us
        </h3>
        <hr className="w-full weight-900 secondary " />

        <div className="gap-4 w-full lg:w-full md:w-[80%]   sm:py-8 xs:py-8 flex justify-center  items-start flex-1 flex-col    ">
          <div className="p-4 bg-[#333] Transition  rounded flex flex-col justify-left items-left ">
            <div className="flex flex-row justify-left items-center ">
              <Check className="important text-white rounded-full White-Border  whiteshadow text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best <span className="text-white ">Tech Team</span>
              </h3>
            </div>
            <p className="Whitext">
              Our team of experienced professionals has a deep knowledge of the
              running technology and is dedicated to staying up-to-date on the
              latest trends and innovations. You can trust us to provide expert
              guidance and advice for all of your IT needs.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best{" "}
                <span span className="text-white ">
                  Quality Service
                </span>
              </h3>
            </div>
            <p className="Whitext">
              We are committed to using only the highest-quality materials and
              components in all of our products. Our services are rigorously
              tested to ensure they meet the highest standards of quality and
              durability
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best{" "}
                <span span className="text-white ">
                  Customer Service
                </span>{" "}
              </h3>
            </div>
            <p className="Whitext">
              We understand that every customer is unique, which is why we offer
              customizable solutions to meet your specific needs. We work
              closely with you to understand your requirements and provide
              tailored solutions that meet your exact specifications.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-1 w-[100%] md:w-[50%]   relative">
        <img
          // src={Slide}
          className="w-full md:w-[100%] cover  h-[100%] md:h-full sm:h-56 xs:h-56  "
          alt=""
        />
      </div>
    </div>
  );
}

export default Whatwedo;
