import React from "react";

import { DesignServices } from "@mui/icons-material";

function TopBox() {
  return (
    <div className="relative flex justify-end items-center gap-4 mt-[-60px] mb-8 px-16 md:px-16 sm:px-4 xs:px-4 z-20   relative">
      <div className="shadow-2xl  contain shadow-zinc-300 h-36 flex flex-col justify-center items-center bg-white rounded-lg">
        <img
          className=" h-full animate-pulse w-56"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEAaT64ggrUvaiOfzYPGBEaLlBWalCncdZEw&usqp=CAU"
        />
      </div>
      <div className="shadow-2xl contain shadow-zinc-300 h-36 flex flex-col justify-center items-center bg-white rounded-lg">
        <img
          className=" h-full  animate-pulse w-56"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToTRWWgtIOp-skCfmOhplo7Vzl0Llt_p9m2Q&usqp=CAU"
        />
      </div>
    </div>
  );
}

export default TopBox;
