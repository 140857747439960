import React from "react";
import WebDev from "../../../Assets/SVGz/Webdev.svg";
import Software from "../../../Assets/SVGz/Software.svg";
import SocialMedia from "../../../Assets/SVGz/Socialmedia.svg";
import Graphics from "../../../Assets/SVGz/Graphicdesign.svg";
import SMS from "../../../Assets/SVGz/sms.svg";

function Blocks() {
  return (
    <div className=" px-16 md:px-16 sm:px-4 xs:px-4  Back2 my-14 lg:my-14  md:my-14  sm:my-14  xs:my-14 ">
      <div className=" flex justify-center w-full flex-col  items-center md:items-left sm:items-left xs:items-left ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black w-full text-center md:text-center sm:text-left xs:text-left mb-4">
          Our Services
        </h3>
        <hr className="w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />
        <p className="text-lg mt-4 text-center lg:text-center md:text-center sm:text-justify xs:text-justify w-[70%] lg:w-[70%] md:w-[70%] sm:w-[100%] xs:w-[100%] primary">
          We specialize in providing
          top-notch solutions to businesses of all sizes. Our team of experts
          is dedicated to helping our clients streamline their operations and
          improve their bottom line.
        </p>
        <div className="mt-8 flex-1 gap-4  grid grid-cols-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 justify-items-center">
          <div className=" rounded-tl-4 rounded-tr-4  rounded-br-4 rounded-bl-4  darkshadow flex-1 justify-center w-[80%] md:w-[100%] sm:w-[80%] xs:w-[80%]   flex-col items-center flex p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={WebDev}
              alt=""
            />
            <h3 className="text-xl text-center font-bold">
              Website development
            </h3>
            <p className="text-lg  primary text-center w-[90%]">
              Unique, memorable, and represent your brand's
            </p>
          </div>
          <div className="  rounded-tl-4 rounded-tr-4  rounded-br-4 rounded-bl-4  darkshadow flex-1 justify-center w-[80%] md:w-[100%] sm:w-[80%] xs:w-[80%]     flex-col items-center flex p-4">
            <img
              fill="black"
              stroke="yellow"
              className="h-28 w-28 "
              src={Software}
              alt=""
            />

            <h3 className="text-xl text-center font-bold">
              Software Development
            </h3>
            <p className="text-lg  primary text-center w-[90%]">
              Unique, memorable, and represent your brand's
            </p>
          </div>
          <div className="  rounded-tl-4 rounded-tr-4  rounded-br-4 rounded-bl-4  darkshadow flex-1 justify-center w-[80%] md:w-[100%] sm:w-[80%] xs:w-[80%]    flex-col items-center flex p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={SocialMedia}
              alt=""
            />

            <h3 className="text-xl text-center font-bold">
              Social Media Marketing
            </h3>
            <p className="text-lg  primary text-center w-[90%]">
              Unique, memorable, and represent your brand's
            </p>
          </div>
          <div className="  rounded-tl-4 rounded-tr-4  rounded-br-4 rounded-bl-4  darkshadow flex-1 justify-center w-[80%] md:w-[100%] sm:w-[80%] xs:w-[80%]    flex-col items-center flex p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={Graphics}
              alt=""
            />

            <h3 className="text-xl text-center font-bold">Graphic Design</h3>
            <p className="text-lg  primary text-center w-[90%]">
              Unique, memorable, and represent your brand's
            </p>
          </div>

          <div className="  rounded-tl-4 rounded-tr-4  rounded-br-4 rounded-bl-4  darkshadow flex-1 justify-center w-[80%] md:w-[100%] sm:w-[80%] xs:w-[80%]     flex-col items-center flex p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={SMS}
              alt=""
            />

            <h3 className="text-xl text-center font-bold">Bulk SMS</h3>
            <p className="text-lg  primary text-center w-[90%]">
              Unique, memorable, and represent your brand's
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blocks;
