import React from "react";
import Logo from "../../Assets/Images/Home/cleanlogowhite.png";
import Icon from "../../Assets/Images/Home/Logo2.png";
import { Button } from "@mui/material";

function Footer() {
  return (
    <div className="bg-[#333] w-full h-full pb-8 mt-8 px-16 md:px-16 sm:px-8 xs:px-8">
      <div className="pt-8">
        {/* Top Horizontal */}
        <div className="flex flex-col justify-center items-top">
          <hr className="Whitext-F w-[100%]  " />
        </div>
        {/* Top Fnevol Detail and Logo */}
        <div className="flex-1 w-[70%] md:w-[70%] sm:w-[100%]  xs:w-[100%] flex flex-row md:flex-row sm:flex-col xs:flex-col justify-center items-center lg:items-center md:items-center sm:items-start xs:items-start p-8 md:p-8 sm:p-4  xs:p-4">
          <img src={Logo} alt=" " className="w-48 h-12 mb-3" />
          <hr className="Whitext-F w-32  rotate-[90deg] lg:rotate-[90deg] md:rotate-[90deg] xs:rotate-[0deg] sm:rotate-[0deg] pt-0 sm:pt-3 xs:pt-3 " />
          <p className="text-lg text-left  Whitext">
            We believe in{" "}
            <span className="secondary">
              Simple<span className="Whitext-F">,</span> Creative{" "}
              <span className="Whitext-F">&</span> Flexible Design
            </span>{" "}
            Standards with a Retina & Responsive Approach can be Highly
            effective in creating visually appealing and user-friendly designs
          </p>
        </div>
        {/* Second Horizonatal liner */}
        <div className="flex flex-col justify-center items-center">
          <hr className="Whitext-F w-[100%]  " />
        </div>
      </div>
      {/*  */}
      {/* Second Box Column */}
      <div className=" pt-16 md:pt-16 sm:pt-4 xs:pt-4  grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
        {/* Getting The Logo Lane */}

        <div className=" flex flex-col justify-center items-left p-8 flex md:flex sm:hidden xs:hidden">
          <img src={Icon} alt=" " className="w-48 " />
        </div>
        {/* Getting The Pages Lane */}
        <div className="mt-0 sm:mt-8 xs:mt-8   w-full h-full">
          <h3 className="Whitext text-2xl font-bold tracking-wider">Pages</h3>
          <ul>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Home
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Services
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Portfolio
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Our Designs
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              About Us
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Contact Us
            </li>
          </ul>
        </div>

        {/* Getting The Services Lane */}

        <div className="mt-0 sm:mt-8 xs:mt-8  w-full h-full">
          <h3 className="Whitext text-2xl font-bold tracking-wider">
            Services
          </h3>
          <ul>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Review
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Bulk SMS
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Graphic Designs
            </li>

            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Web Design
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Software Design
            </li>
            <li className="Whitext-F text-lg font-normal Transition-Fast tracking-wider">
              Social Media Managing
            </li>
          </ul>
        </div>

        <div className="mt-0 sm:mt-8 xs:mt-8  w-full h-full flex flex-col items-start">
          <div className="flex-row flex">
            <h3 className="Whitext text-xl font-bold">Call Us : </h3>
            <h3 className="Whitext-F text-xl pl-2 font-bold">
              {" "}
              +2547 1624 7699
            </h3>
          </div>
          <div className="flex-row flex">
            <h3 className="Whitext text-xl font-bold">Email Us : </h3>
            <h3 className="Whitext-F text-xl pl-2 font-bold">
              info@fnevol.com
            </h3>
          </div>
          <div className="mt-4">
            <div>
              <h3 className="Whitext-F text-xl font-bold">Follow Us </h3>
            </div>
            {/* The Icons */}
            <div className="flex flex-row gap-4 ">
              <div className="rounded-full bg-blue-400 h-12 w-12 justify-center items-center flex ">
                <h5 className="text-5xl  font-bold Whitext-F">f</h5>
              </div>
              <div className="rounded-full bg-blue-400 h-12 w-12 justify-center items-center flex ">
                <h5 className="text-4xl  font-red Whitext-F">W</h5>
              </div>
              <div className="rounded-full bg-green-400 h-12 w-12 justify-center items-center flex ">
                <h5 className="text-5xl  font-bold Whitext-F">X</h5>
              </div>
              <div className="rounded-full bg-white h-12 w-12 justify-center items-center flex ">
                <h5 className="text-5xl  font-bold Whitext-F">T</h5>
              </div>
            </div>
            {/* Contact US Button */}
            <button className=" mt-8 text-xl w-36 h-12 YellowBorder text-white">
              Lets Talk
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
