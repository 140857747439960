import { Search, ZoomIn, ZoomInMap } from "@mui/icons-material";
import React from "react";
import Work1 from "../../../../Assets/Images/Graphic/work1.jpg";
import Work2 from "../../../../Assets/Images/Graphic/work2.jpg";
import Work3 from "../../../../Assets/Images/Graphic/work3.jpg";
import Work4 from "../../../../Assets/Images/Graphic/work4.jpg";
import Work5 from "../../../../Assets/Images/Graphic/work5.jpg";
import Work6 from "../../../../Assets/Images/Graphic/work6.jpg";

function portfoliio() {
  return (
    <div className="PrimaryB py-8 px-16 md:px-16 sm:px-4 xs:px-4 mt-0 mb-8 ">
      <div>
        <h2 className="tracking-wider text-center md:text-center sm:text-left xs:text-left mb-4 text-4xl mt-4 font-black text-white">
          Our Work
        </h2>
      </div>
      <div className="grid md:grid sm:hidden xs:hidden mb-12 mt-12 grid-cols-6 md:grid-cols-6   sm:grid-cols-2 xs:grid-cols-2 gap-4">
        <div className="group relative flex justify-center items-center relative w-full col-start-1  col-end-3 sm:cols-start-0 xs:cols-start-0 bg-red-100 h-56 darkshadow ZoomControl">
          <img
            src={Work1}
            className="z-20 opacity-100 bg-red-900   absolute h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full col-start-3  h-56 col-span-3 sm:cols-start-0 xs:cols-start-0 sm:col-span-0 xs:col-span-0  darkshadow ZoomControl">
          <img
            src={Work2}
            className="h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full col-start-6 col-end-7 h-56 darkshadow sm:cols-start-0 xs:cols-start-0 sm:col-span-0 xs:col-span-0  ZoomControl">
          <img
            src={Work3}
            className="h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full col-start-1  h-56 col-end-2 darkshadow sm:cols-start-0 xs:cols-start-0 sm:col-span-0 xs:col-span-0  ZoomControl">
          <img
            src={Work4}
            className="h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full col-start-2 col-span-3 bg-red-100 h-56 darkshadow sm:cols-start-0 xs:cols-start-0 sm:col-span-0 xs:col-span-0  ZoomControl">
          <img
            src={Work5}
            className="h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full col-start-5 col-end-7 bg-red-100 h-56 darkshadow sm:cols-start-0 xs:cols-start-0  ZoomControl">
          <img
            src={Work6}
            className="h-56 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
      </div>

      {/* Display on Mobiles */}
      <div className="hidden md:hidden sm:grid xs:grid gap-2 grid-cols-2 ">
        <div className="w-full h-full shadower ZoomControl">
          <img
            src={Work1}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full shadower  ZoomControl">
          <img
            src={Work2}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>{" "}
        <div className="w-full h-full shadower ZoomControl">
          <img
            src={Work3}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full shadower ZoomControl">
          <img
            src={Work4}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>{" "}
        <div className="w-full h-full shadower ZoomControl">
          <img
            src={Work5}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
        <div className="w-full h-full shadower ZoomControl">
          <img
            src={Work6}
            className="h-40 w-full Zoomer "
            alt="fnevol Graphic Work"
          />
        </div>
      </div>
    </div>
  );
}

export default portfoliio;
