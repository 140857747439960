import React from "react";
import LetsGo from "../../../Assets/Images/Home/LetsGo.png";
import { MilitaryTechSharp } from "@mui/icons-material";


function PicSpacer() {
  return (
    <div className=" w-full   h-[400px] PicSpacerBG">
      <div className="shader flex flex-col justify-center h-full w-full  items-center flex-col ">
        <img
          src={LetsGo}
          className="w-[50%] lg:w-[50%] md:w-[80%] sm:w-[80%] xs:w-[80%]"
        />
        <MilitaryTechSharp className="importnat text-white font-[30px] p-3 rounded-full border " />
       
      </div>
    </div>
  );
}

export default PicSpacer;
