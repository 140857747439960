import React from 'react'
import LandingFace from './Components/LandingFace'
import Whatwedo from './Components/Whatwedo'
import Whoweare from './Components/Whoweare'
import Blocks from './Components/Blocks'
import PicSpacer from './Components/PicSpacer'
import TopBox from './Components/TopBox'
import Testimonials from './Components/Testimonials'

function Home() {
  return (
    <div>
        <LandingFace/>
        <TopBox/>
        <Whoweare/>
        <Blocks/>
        <Whatwedo/>
        <PicSpacer/>
    </div>
  )
}

export default Home