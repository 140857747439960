import React from "react";
import Lady from "../../../Assets/Images/Home/lady.png";
import WebDev from "../../../Assets/SVGz/Webdev.svg";
import Software from "../../../Assets/SVGz/Software.svg";
import SocialMedia from "../../../Assets/SVGz/Socialmedia.svg";
import Graphics from "../../../Assets/SVGz/Graphicdesign.svg";
import SMS from "../../../Assets/SVGz/sms.svg";


function Testimonials() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="my-28 lg:my-28 md:my-28 sm:my-28 xs:my-28 px-16 h-[350px]   md:px-16 sm:px-4 xs:px-4 gap-4 flex flex-row  md:flex-row sm:flex-col xs:flex-col justify-center items-start w-full">
      <div className="pt-6 rounded px-4 w-full  h-[100%] flex-[80%] lg:flex-[80%] md:flex-[80%] gap-4 sm:flex-[100%]  xs:flex-[100%] darkshadowB">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black  text-left md:text-left sm:text-left xs:text-left mb-4">
          Our Clients
        </h3>
        <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />
        <div className="grid grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 justify-evenly items-center h-full pb-12">
          <img
            fill="black"
            stroke="yellow"
            className=" h-36 w-36 lg:w-36 lg:h-30 md:w-30 sm:w-25  xs:w-25 md:h-30 sm:h-25 xs:h-25 p-2 rounded-full darkshadow"
            src={WebDev}
            alt=""
          />
          <img
            fill="black"
            stroke="yellow"
            className="h-36 w-36 lg:w-36 lg:h-30 md:w-30 sm:w-25  xs:w-25 md:h-30 sm:h-25 xs:h-25 p-2 rounded-full darkshadow"
            src={Graphics}
            alt=""
          />
          <img
            fill="black"
            stroke="yellow"
            className="h-36 w-36 lg:w-36 lg:h-30 md:w-30 sm:w-25  xs:w-25 md:h-30 sm:h-25 xs:h-25 p-2 rounded-full darkshadow"
            src={SMS}
            alt=""
          />
          <img
            fill="black"
            stroke="yellow"
            className=" h-36 w-36 lg:w-36 lg:h-30 md:w-30 sm:w-25  xs:w-25 md:h-30 sm:h-25 xs:h-25 p-2 rounded-full darkshadow"
            src={Graphics}
            alt=""
          />
          <img
            fill="black"
            stroke="yellow"
            className=" h-36 w-36 lg:w-36 lg:h-30 md:w-30 sm:w-25  xs:w-25 md:h-30 sm:h-25 xs:h-25 p-2 rounded-full darkshadow"
            src={Software}
            alt=""
          />
        </div>
      </div>
      <div className=" flex-[20%] md:flex-[20%]  sm:flex-[100%] xs:flex-[100%]">
        <div className="p-4   bg-white darkshadowB   h-[100%] ">
          <h3 className="pt-6 primary text-xl md:text-xl sm:text-4xl xs:text-4xl font-black  text-left md:text-left sm:text-left xs:text-left mb-4">
            Review
          </h3>
          <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />

          <div className="  flex-1 flex justify-start sm:justify-between w-full xs:justify-between sm:gap-4 xs:gap-4 flex-col md:flex-col sm:flex-row xs:flex-row items-center md:items-center sm:items-center xs:items-center  p-4 md: p-4 sm:p-0 xs:p-0">
            <img
              className=" rounded-full bg-freen-900  darkshadowB sm:m-2 xs:m-2  h-28 w-28 md:h-28 sm:h-20 xs:h-20 md:w-20 sm:w-20 xs:w-20 "
              src={Lady}
              alt=""
            />
            <div>
              <h3 className="text-lg text-center md:text-center sm:text-left xs:text-left font-bold">
                Desker Org
              </h3>
              <p className="  primary text-center md:text-center sm:text-left xs:text-left ">
                Unique, memorable, and represent your brand's
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
