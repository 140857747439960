import React from "react";
import Unique from "../../../Assets/Images/Home/UNIQUE.png";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

function LandingFace() {
  return (
    <div className="HomePageLanding  h-[100vh] w-full relative z-10">
      <div className="absolute flex justify-center items-start mt-[30vh] w-full h-full">
        <img
          src={Unique}
          className="w-[50%] lg:w-[50%] md:w-[80%] sm:w-[80%] xs:w-[80%]"
        />
      </div>
      <div className="flex flex-col items-center justify-center  h-full justify-center   ">
        <h2 className="text-5xl md:text-5xl sm:text-3xl xs:text-3xl primary font-bold mt-[35vh] lg:mt-[35vh] md:mt-[15vh] sm:mt-[15vh] xs:mt-[15vh] bg-white px-4 py-2 rounded-full text-center darkshadow ">
          & Custom Design
        </h2>

        {/* Button */}
        <button className="mt-8 Whiteshadow text-xl  Primary text-white w-40 text-center rounded-tr-full rounded-br-full rounded-bl-full h-12">
          Check More
        </button>

        {/* Blocks and icons */}
        <div className="mt-4 flex flex-row gap-3  bg-white px-4 py-2 rounded-full whiteshadow">
          <div className="w-12  h-12 darkshadow flex justify-center items-center rounded-full">
            <Instagram className="important font-xl secondary" />
          </div>
          <div className="w-12 h-12 darkshadow flex justify-center items-center rounded-full">
            <Facebook className="important font-xl secondary" />
          </div>
          <div className="w-12 h-12 darkshadow flex justify-center items-center rounded-full">
            <LinkedIn className="important font-xl secondary" />
          </div>
          <div className="w-12 h-12 darkshadow flex justify-center items-center rounded-full">
            <Twitter className="important font-xl secondary" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingFace;
